<template>
    <div>
        <template v-if="text">
            {{$moment(text).format('DD.MM.YYYY в HH:mm')}}
        </template>
        <template v-else>
            <a-tag>
                Нет даты
            </a-tag>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String
        }
    }
}
</script>